let tid: any

export default function handleCookie(): void {
    tid = setInterval(()=> {
        const dialog = document.getElementById('coiConsentBanner')

        if (dialog) {
            dialog.style.transform = 'translateY(0)'
            clearInterval(tid)
        }
    }, 2000)
}