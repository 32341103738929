import './style/PartTwo.scss'
import React, {useRef} from 'react'
import cn from 'classnames'
import Container from './Container'
import { Heading, Section } from '../utils'
import { useVisibility } from '../data/hooks'

export default function PartTwo(): any {
    const ref = useRef<HTMLDivElement>(null)
    const visible = useVisibility(ref)

    return (
        <Container>
            <div className="part-two" ref={ref}>
                <div className={cn('part-two__border', { 'part-two__border--active': visible })} />

                <div className={cn('part-two__num animated animated--a animated--bottom-small h h--1', { 'animated--active': visible })}>2.</div>

                <Heading variety={1} className={cn('part-two__title animated animated--a', { 'animated--active': visible })}>
                    Featured Capabilities
                </Heading>
                <div className="part-two__text">
                    <p className={cn('text text--lo text--medium animated animated--b', { 'animated--active': visible })}>We pair great minds across strategy, design and tech with a can-do attitude to build impactful solutions.</p>
                    <p className={cn('text text--lo text--medium animated animated--c', { 'animated--active': visible })}>Greatly designed digital solutions &mdash; crafted on insightful research, knowledgeable tech and creative edge &mdash; raise the bar and define new positive ways to benefit humans and businesses.</p>
                </div> 

                <ul className="part-two__list">
                    <li className={cn('part-two__list__item animated animated--bottom animated--e', { 'animated--active': visible })}>
                        <Section> 
                            <div className="part-two__list__item__i">A</div>
                            <Heading className="part-two__list__item__title">E-commerce</Heading>
                            <p className="text text--lo text--small">We create meaningful and powerful e-commerce solutions that fuel business growth and enable next-generation services and experiences.</p>
                        </Section>
                    </li> 
                    <li  className={cn('part-two__list__item animated animated--bottom animated--f', { 'animated--active': visible })}>
                        <Section> 
                            <div className="part-two__list__item__i">B</div>
                            <Heading className="part-two__list__item__title">Digital Experience Platforms</Heading>
                            <p className="text text--lo text--small">Great customer experience in every touchpoint powered by flexible architecture; that’s how we build digital ecosystems to maximize user engagement.</p>
                        </Section>
                    </li> 
                    <li className={cn('part-two__list__item animated animated--bottom animated--g', { 'animated--active': visible })}>
                        <Section> 
                            <div className="part-two__list__item__i">C</div>
                            <Heading className="part-two__list__item__title">Digital product and services</Heading>
                            <p className="text text--lo text--small">Seamless, cross-channel, relevant, personalised, empowering, and secure. Those are some of our key focal points to building winning digital experiences.</p>
                        </Section>
                    </li> 
                    <li className={cn('part-two__list__item animated animated--bottom animated--h', { 'animated--active': visible })}>
                        <Section> 
                            <div className="part-two__list__item__i">D</div>
                            <Heading className="part-two__list__item__title">Strategy and innovation</Heading>
                            <p className="text text--lo text--small">We know how to unlock new potential, and we lead the way to achieving innovative success across markets, products, business models, etc.</p>
                        </Section>
                    </li> 
                </ul>
            </div>
        </Container>
    )
}