import React, { useEffect, useState, useRef, useLayoutEffect, useMemo, useCallback } from 'react'
import useAnimationFrame from 'use-animation-frame'
import cn from 'classnames'
import { easeInOutQuad, clamp } from '../utils'
import { useVisibility } from '../data/hooks'

export default function Static({
    className,
    mouseOver,
    cutoffTop = true,
    cutoffBottom = false
}: {
    className: any,
    mouseOver: boolean,
    cutoffTop?: boolean,
    cutoffBottom?: boolean,
}): any {
    const ref = useRef<SVGSVGElement>(null)
    const [[width, height], setSize] = useState([0, 0])
    const visible = useVisibility(ref, 0, '15% 0% 15% 0%', false)
    const defaultX = window.innerWidth * .8
    const positionOffsetScale = useMemo(() => {
        return  (1 - clamp((window.innerWidth - 400) / 900, 0, 1)) * 3
    }, []) 
    const lines = useMemo(() => {
        const count = clamp(Math.floor((40 / 1500) * window.innerWidth), 20, 60)

        return new Array(count).fill(null).map(() => [Math.random() * width, Math.random() * 50])
    }, [width])
    const saveSize = useCallback(() => {
        if (ref.current) {
            const { width, height } = ref.current.getBoundingClientRect()

            setSize([width, height])
        }
    }, [])

    useLayoutEffect(() => {
        saveSize()
    }, [saveSize]) 

    useEffect(() => {
        let tid: any
        const onResize = () => {
            clearTimeout(tid)
            tid = setTimeout(saveSize, 150)
        }
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
            clearTimeout(tid)
        }
    }, [saveSize])

    return (
        <svg
            ref={ref}
            className={cn(className)}
            viewBox={`0 0 ${width} ${height}`}
            style={{ width: '100%', display: 'block', overflow: 'visible' }}
        >
            {lines.map(([x, z], index) => {
                return <Line positionOffsetScale={positionOffsetScale} x={x} z={z} defaultX={defaultX} mouseOver={mouseOver} visible={visible} height={height} key={index} cutoffBottom={cutoffBottom} cutoffTop={cutoffTop} />
            })}
        </svg>
    )
}

function Line({
    x,
    z,
    height,
    mouseOver,
    positionOffsetScale,
    cutoffBottom,
    cutoffTop,
    visible,
    defaultX
}: {
    x: number,
    z: number,
    height: number
    mouseOver: boolean,
    cutoffBottom: boolean,
    positionOffsetScale:number,
    cutoffTop: boolean,
    visible: boolean,
    defaultX: number
}) {
    const [scale] = useState(() => Math.random() * .025 + .005)
    const ref = useRef<SVGLineElement>(null)
    const mouseX = useRef<number>(defaultX)
    // worst var names ever
    const ex = useRef<number>(x) // offsetx
    const tex = useRef<number>(x) // target offsetx
    const d = useRef<number>(0) // distnance
    const d2 = useRef<number>(.25) // target offsetx
    const tid = useRef<any>(null)

    useEffect(() => {
        const isSmallScreen = window.matchMedia('(max-width: 900px').matches

        if (!isSmallScreen) {
            const onMouseMove = (e: MouseEvent) => {
                mouseX.current = e.pageX
            }

            window.addEventListener('mousemove', onMouseMove)

            return () => {
                window.removeEventListener('mousemove', onMouseMove)
            }
        }
    }, [])

    useEffect(() => {
        if (visible) {
            tid.current = setInterval(() => {
                d.current = Math.random() * clamp(window.innerWidth * .2, 200, 600)
                d2.current = (1 - (Math.random() * .5 + .125))
            }, Math.random() * 500 + 1000)

            return () => {
                clearInterval(tid.current)
            }
        }
    }, [mouseOver, visible])

    useAnimationFrame(() => {
        if (!visible) {
            return
        }

        if (ref.current) {
            const dx = mouseX.current - (x)
            let r = easeInOutQuad(clamp(1 - Math.abs(dx / (d.current * 2)), 0, 1))

            if (Math.abs(dx) < d.current) {
                tex.current = dx * d2.current
            } else {
                tex.current = -dx / 10
                r = 0
            }

            ex.current += (tex.current - ex.current) * scale
            ref.current.style.transform = `translate3d(${ex.current.toFixed(1)}px, 0, -${z * 50}px)`
            ref.current.style.strokeWidth = `${(r * 5 + 1).toFixed(1)}px`
            ref.current.style.willChange = 'transform stroke-width'
        }
    }) 

    return (
        <line
            ref={ref}
            x1={x}
            y1={cutoffTop ? 0 : -z * (5 -positionOffsetScale)}
            x2={x}
            y2={height + (cutoffBottom ? 0 : z * (5 - positionOffsetScale))}
            stroke="currentColor"
        />
    )
}
