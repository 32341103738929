import { RouteComponentProps } from '@reach/router'

import React from 'react'
import GetInTouch from '../components/GetInTouch'
import NoaFamily from '../components/NoaFamily'
import { Section } from '../utils'
import PrivacyPolicyModule from '../components/PrivacyPolicy'

const PrivacyPolicy: React.FC<RouteComponentProps> = () => {
    return (
        <Section>
            <PrivacyPolicyModule />
            <GetInTouch />
            <NoaFamily />
        </Section>
    )
}

export default PrivacyPolicy
