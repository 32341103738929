import './style/GetInTouch.scss'
import React, {useRef} from 'react'
import cn from 'classnames'
import Container from './Container'
import { Heading, Section } from '../utils'
import { useVisibility } from '../data/hooks'

export default function GetInTouch(): JSX.Element {
    const ref = useRef<HTMLDivElement>(null)
    const visible = useVisibility(ref)

    return (
        <Container>
            <div ref={ref} className="get-in-touch" id="get-in-touch">
                <div className={cn('get-in-touch__border', { 'get-in-touch__border--active': visible })} />

                <div className={cn('get-in-touch__num animated animated--b animated--bottom-small h h--1', { 'animated--active': visible })}>
                    3.
                </div>

                <Heading variety={1} className={cn('get-in-touch__title animated animated--a', { 'animated--active': visible })}>
                    Let’s get in touch
                </Heading>
                <div className="get-in-touch__text">
                    <p className={cn('text text--medium text--lo animated animated--c', { 'animated--active': visible })}>
                        300+ people, 4 countries, one goal to rule them all:
                        <br /> <br />
                        Being your powerhouse for digital growth.
                    </p>
                </div>

                <ul className="get-in-touch__list">
                    <li className={cn('get-in-touch__list__item animated animated--bottom animated--f', { 'animated--active': visible })}>
                        <Section> 
                            <Heading className="visually-hidden">Norway</Heading>
                            <address>
                                <a href="https://www.noaignite.no" target="_blank" rel="noopener noreferrer" className="get-in-touch__list__item__url">
                                    <span className="get-in-touch__list__item__url__tag text text--medium text--lo">Go to NoA Ignite</span>
                                    {' '}
                                    <strong className="get-in-touch__list__item__url__country h h--1">Norway</strong>
                                
                                    <svg className="get-in-touch__list__item__url__icon" viewBox="0 0 36 36">
                                        <path d="M18.2025 0.15882L15.2461 3.11523L32.9846 20.8537L35.941 17.8973L18.2025 0.15882Z" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.0905 19.9855L2.0905 15.8045L33.7016 15.8067V19.9877L2.0905 19.9855Z" />
                                        <path d="M35.9418 17.8983L32.9854 14.9419L15.2469 32.6804L18.2033 35.6368L35.9418 17.8983Z" />
                                    </svg>
                                </a>
                                <p className="get-in-touch__list__item__address text text--fluffy text--small">
                                    Wergelandsveien 25, <br />
                                    0187 Oslo
                                </p>
                                <p className="text text--fluffy text--small">
                                    Kristine Bjørnstad <br />
                                    CEO NoA Ignite Norway <br /> 
                                    <a href="mailto:kristine.bjornstad@noaignite.com" className="get-in-touch__list__item__email">
                                        kristine.bjornstad@noaignite.com
                                    </a> 
                                </p>
                            </address>
                        </Section>
                    </li>
                    <li className={cn('get-in-touch__list__item animated animated--bottom animated--g', { 'animated--active': visible })}>
                        <Section> 
                            <Heading className="visually-hidden">Sweden</Heading>
                            <address>
                                <a href="https://www.noaignite.se" target="_blank" rel="noopener noreferrer" className="get-in-touch__list__item__url">
                                    <span className="get-in-touch__list__item__url__tag text--medium text--lo">Go to NoA Ignite</span>
                                    {' '} 
                                    <strong className="get-in-touch__list__item__url__country h h--1">Sweden</strong>
                                
                                    <svg className="get-in-touch__list__item__url__icon" viewBox="0 0 36 36">
                                        <path d="M18.2025 0.15882L15.2461 3.11523L32.9846 20.8537L35.941 17.8973L18.2025 0.15882Z" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.0905 19.9855L2.0905 15.8045L33.7016 15.8067V19.9877L2.0905 19.9855Z" />
                                        <path d="M35.9418 17.8983L32.9854 14.9419L15.2469 32.6804L18.2033 35.6368L35.9418 17.8983Z" />
                                    </svg>
                                </a>
                                <p className="get-in-touch__list__item__address text text--fluffy text--small">
                                    Tulegatan 13, <br />
                                    113 53 Stockholm
                                </p>
                                <p className="text  text--fluffy text--small">
                                    Jonathan Pettersson <br />
                                    CEO NoA Ignite Sweden <br />
                                    <a href="mailto:jonathan.pettersson@noaignite.com" className="get-in-touch__list__item__email">
                                        jonathan.pettersson&shy;@noaignite.com
                                    </a> 
                                </p>
                            </address>
                        </Section>
                    </li>
                    <li className={cn('get-in-touch__list__item animated animated--bottom animated--h', { 'animated--active': visible })}>
                        <Section>
                            <Heading className="visually-hidden">Denmark</Heading>  
                            <address>
                                <a href="https://www.noaignite.dk" target="_blank" rel="noopener noreferrer" className="get-in-touch__list__item__url">
                                    <span className="get-in-touch__list__item__url__tag text--medium text--lo">Go to NoA Ignite</span>
                                    {' '}
                                    <strong className="get-in-touch__list__item__url__country h h--1">Denmark</strong>
                                
                                    <svg className="get-in-touch__list__item__url__icon" viewBox="0 0 36 36">
                                        <path d="M18.2025 0.15882L15.2461 3.11523L32.9846 20.8537L35.941 17.8973L18.2025 0.15882Z" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.0905 19.9855L2.0905 15.8045L33.7016 15.8067V19.9877L2.0905 19.9855Z" />
                                        <path d="M35.9418 17.8983L32.9854 14.9419L15.2469 32.6804L18.2033 35.6368L35.9418 17.8983Z" />
                                    </svg>
                                </a>
                                <p className="get-in-touch__list__item__address text text--fluffy text--small">
                                    Adelgade 12, 1. floor <br/>
                                    DK-1304 Copenhagen
                                </p>
                                <p className="text text--fluffy text--small">
                                    Jakob Langemark <br />
                                    CEO NoA Ignite Denmark <br />
                                    <a href="mailto:jl@noaignite.com" className="get-in-touch__list__item__email">
                                        jl@noaignite.com
                                    </a>
                                </p>
                            </address>
                        </Section>
                    </li>
                    <li className={cn('get-in-touch__list__item animated animated--bottom animated--i', { 'animated--active': visible })}>
                        <Section>
                            <Heading className="visually-hidden">Poland</Heading>
                            <address>
                                <a href="https://www.noaignite.pl" target="_blank" rel="noopener noreferrer" className="get-in-touch__list__item__url">
                                    <span className="get-in-touch__list__item__url__tag text--medium text--lo">Go to NoA Ignite</span>
                                    {' '}
                                    <strong className="get-in-touch__list__item__url__country h h--1">Poland</strong>
                                
                                    <svg className="get-in-touch__list__item__url__icon" viewBox="0 0 36 36">
                                        <path d="M18.2025 0.15882L15.2461 3.11523L32.9846 20.8537L35.941 17.8973L18.2025 0.15882Z" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.0905 19.9855L2.0905 15.8045L33.7016 15.8067V19.9877L2.0905 19.9855Z" />
                                        <path d="M35.9418 17.8983L32.9854 14.9419L15.2469 32.6804L18.2033 35.6368L35.9418 17.8983Z" />
                                    </svg>
                                </a>
                                <p className="get-in-touch__list__item__address text text--fluffy text--small">
                                    Adama Asnyka 9,  <br />
                                    31-144 Kraków
                                </p>
                                <p className="text text--fluffy text--small">
                                    Dariusz Macina  <br />
                                    CEO NoA Ignite Poland <br />
                                    <a href="mailto:dariusz.macina@noaignite.com" className="get-in-touch__list__item__email">
                                        dariusz.macina@noaignite.com
                                    </a>  
                                </p>
                            </address>
                        </Section>
                    </li>
                </ul>
            </div>
        </Container>
    )
}