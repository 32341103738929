import { RouteComponentProps } from '@reach/router'

import React from 'react'
import Clients from '../components/Clients'
import GetInTouch from '../components/GetInTouch'
import Hero from '../components/Hero'
import Intro from '../components/Intro'
import NoaFamily from '../components/NoaFamily'
import PartOne from '../components/PartOne'
import PartTwo from '../components/PartTwo'
import { Section } from '../utils'

const Home: React.FC<RouteComponentProps> = () => {
    return (
        <Section>
            <Hero />
            <Intro />
            <PartOne />
            <PartTwo />
            <Clients />
            <GetInTouch />
            <NoaFamily />
        </Section>
    )
}

export default Home
