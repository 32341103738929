import './style/PartOne.scss'
import React, { useRef } from 'react'
import cn from 'classnames'
import Container from './Container'
import { Heading } from '../utils'
import Image from './Image'
import { useVisibility } from '../data/hooks'

export default function PartOne(): any {
    const ref = useRef<HTMLDivElement>(null)
    const visible = useVisibility(ref)

    return (
        <Container>
            <div className="part-one" ref={ref}>
                <div className={cn('part-one__border', { 'part-one__border--active': visible })} />

                <div className={cn('part-one__num animated animated--b animated--bottom-small h h--1', { 'animated--active': visible })}>1.</div>

                <Heading className={cn('part-one__title animated animated--a', { 'animated--active': visible })} variety={1}>
                    Ignite business impact with superior digital products and services
                </Heading>
                <div className="part-one__text">
                    <p className={cn('text text--lo text--medium animated animated--d', { 'animated--active': visible })}>
                        People, businesses, and organizations embrace brands that deliver customer-centric and business impactful digital products. At NoA Ignite, we lead the way for companies to win by crafting such digital products and services.
                    </p>
                    <p className={cn('text text--lo text--medium animated animated--e', { 'animated--active': visible })}>
                        Deep human insights, creative excellence, data, and technology allow us to shape digital solutions that deliver significant value to people and the brands that serve them.
                    </p>
                    <p className={cn('text text--lo text--medium animated animated--f', { 'animated--active': visible })}>
                        This is how we solve and ignite even the most complex digital growth challenges.
                    </p>
                    <p className={cn('text text--lo text--medium animated animated--g', { 'animated--active': visible })}>
                        We are +300 easygoing, diverse, techy, eager, creative and relentlessly curious people working together across four countries.
                    </p>
                    <p className={cn('text text--lo text--medium animated animated--h', { 'animated--active': visible })}>
                        Born in the Nordics, raised by unheard-of digital challenges, here to positively impact businesses and humans across the globe.
                    </p>
                    <p className={cn('text text--lo text--medium animated animated--i', { 'animated--active': visible })}>
                        NoA Ignite &mdash; Your powerhouse for digital growth.
                    </p>
                </div>

                <div className={cn('part-one__image animated animated--i animated--bottom', { 'animated--active': visible })}>
                    <Image
                        width={1360}
                        height={583}
                        src="/static/images/hookbluebig.jpg"
                        srcSet="/static/images/hookbluebig.jpg 20010w, /static/images/hookbluebig-s.jpg 1360w, /static/images/hookbluebig-xs.jpg 816w"
                        sizes="(max-width:1400px) 100vw, 1400px"
                    />
                </div>
            </div>
        </Container>
    )
}