import React, { useCallback, useState, useLayoutEffect, useEffect, useRef, useMemo } from 'react'
import useAnimationFrame from 'use-animation-frame'
import { clamp, easeInOutQuad } from '../utils'
import spline from '../data/spline'
import { useVisibility } from '../data/hooks'

export default function Spline({
    className,
    defaultMouse = 0,
    endAt = 'bottom',
}: {
    defaultMouse?: number,
    endAt?: 'bottom' | 'top',
    className?: string
}): JSX.Element {
    const ref = useRef<HTMLCanvasElement>(null)
    const t = useRef(.95)
    const mouse = useRef(0) 
    const targetMouse = useRef(defaultMouse)
    const [[dimensionX, dimensionY], setDimension] = useState([0, 0])
    const visible = useVisibility(ref, 0, '0% 0% 0% 0%', false)
    const dpr = clamp(window.devicePixelRatio * .75, 1, 2)
    const saveSize = useCallback(() => {
        if (ref.current) {
            const { width, height } = ref.current.getBoundingClientRect()

            setDimension([width, height])
        }
    }, [])
    const { path, rotationRatio, thickness, xOffset, yOffset, incrementScale } = useMemo(() => {
        if (window.matchMedia('(max-width: 550px)').matches) { 
            return {
                path: spline([
                    dimensionX * -.25, dimensionY * (.25 + (endAt === 'top' ? .5 : 0)),
                    dimensionX * .55, dimensionY * (.3 + (endAt === 'top' ? .35 : 0)),
                    dimensionX * 1.25, dimensionY * (.45 + (endAt === 'top' ? .5 : 0)),
                ], .65, 20),
                rotationRatio: .45,
                thickness: 6,
                xOffset: 10,
                yOffset: 50,
                incrementScale: .015
            }
        }
        if (window.matchMedia('(max-width: 750px)').matches) { 
            return {
                path: spline([
                    dimensionX * -.25, dimensionY * (.29 + (endAt === 'top' ? .5 : 0)),
                    dimensionX * .5, dimensionY * (.3 + (endAt === 'top' ? .35 : 0)),
                    dimensionX * 1.25, dimensionY * (.48 + (endAt === 'top' ? .5 : 0)),
                ], .65, 24),
                rotationRatio: .45,
                thickness: 7,
                xOffset: 40,
                yOffset: 50,
                incrementScale: .0025
            }
        }
        if (window.matchMedia('(max-width: 1300px)').matches) { 
            return {
                path: spline([
                    dimensionX * -.25, dimensionY * (.35 + (endAt === 'top' ? .5 : 0)),
                    dimensionX * .45, dimensionY * (.3 + (endAt === 'top' ? .35 : 0)),
                    dimensionX * 1.25, dimensionY * (.5 + (endAt === 'top' ? .5 : 0)),
                ], .65, 23),
                rotationRatio: .75,
                thickness: 15,
                xOffset: 40,
                yOffset: 50,
                incrementScale: .0045
            }
        }

        return {
            path: spline([
                dimensionX * -.25, dimensionY * (.22 + (endAt === 'top' ? .75 : 0)),
                dimensionX * .5, dimensionY * (.15 + (endAt === 'top' ? .45 : 0)),
                dimensionX * 1.25, dimensionY * (.5 + (endAt === 'top' ? .85 : 0)),
            ], .65, 35),
            rotationRatio: .85,
            thickness: 25,
            xOffset: 55,
            yOffset: 75,
            incrementScale: .0045
        }
    }, [dimensionX, dimensionY, endAt])
    const render = useCallback(() => {
        if (ref.current) {
            const context = ref.current.getContext('2d') as CanvasRenderingContext2D

            context.clearRect(0, 0, dimensionX * dpr, dimensionY * dpr)
            context.fillStyle = 'black'

            for (let i = 0; i < path.length - 2; i += 2) {
                const x = path[i]
                const y = path[i + 1]

                const rotation = Math.atan2(path[i + 3] - y, path[i + 2] - x) * rotationRatio
                const ye = Math.cos(t.current + x * incrementScale) * yOffset
                const xe = Math.sin(t.current + x * incrementScale) * xOffset
                const distance = 1 - easeInOutQuad(clamp(Math.abs(mouse.current - (x + xe)) / Math.min(600, window.innerWidth * .5), 0, 1))
                const width = distance * thickness + 1
                const height = dimensionY

                context.scale(dpr, dpr)
                context.translate(x + xe, y + ye)
                context.rotate(rotation)
                context.fillRect(-width / 2, -height / 2, width, height)
                context.resetTransform()
            }
        }
    }, [dimensionY, thickness, dimensionX, dpr, incrementScale, path, rotationRatio, yOffset, xOffset])

    useEffect(() => {
        let tid: any
        const onResize = () => {
            clearTimeout(tid)
            tid = setTimeout(saveSize, 50)
        }
        window.addEventListener('resize', onResize)
    }, [saveSize])

    useLayoutEffect(() => {
        saveSize()
    }, [saveSize])

    useEffect(() => {
        const isSmallScreen = window.matchMedia('(max-width: 900px').matches

        if (!isSmallScreen) {
            const onMouseMove = (e: MouseEvent) => {
                targetMouse.current = e.clientX
            }
            window.addEventListener('mousemove', onMouseMove)

            return () => {
                window.removeEventListener('mousemove', onMouseMove)
            }
        }
    }, [render])

    useAnimationFrame(() => {
        if (visible) {
            t.current += .01
            mouse.current += (targetMouse.current - mouse.current) * .05

            render()
        }
    })

    return (
        <canvas 
            className={className}
            width={dimensionX * dpr}
            height={dimensionY * dpr}
            ref={ref}
        />
    )
}