import './assets/style/app.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Router, Location } from '@reach/router'
import Header from './components/Header'
import { Heading } from './utils'
import Footer from './components/Footer'
import { HeroGraphic } from './data/graphic'
import { polyfill } from './data/polyfill'
import Config from './data/Config'
import FontLoader from './components/FontLoader'
import handleCookie from './data/handle-cookiemonster'
import Home from './pages/home'
import PrivacyPolicy from './pages/privacy-policy'

polyfill()
handleCookie()

if (Config.ENV === 'production') {
    TagManager.initialize({
        gtmId: Config.GTM_ID,
    })
}

const FramerRouter = ({ children }: { children: any }) => (
    <Location>
        {({ location }) => (
            <>
                <Router key={location.key} location={location} primary={false}>
                    {children}
                </Router>
            </>
        )}
    </Location>
)

const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
        document.body.classList.add('user-is-tabbing')
        window.removeEventListener('keydown', onKeyDown)
        window.addEventListener('mousedown', onMouseDown)
    }
}
const onMouseDown = () => {
    document.body.classList.remove('user-is-tabbing')
    window.removeEventListener('mousedown', onMouseDown)
    window.addEventListener('keydown', onKeyDown)
}

window.addEventListener('keydown', onKeyDown)

function App() {
    return (
        <FontLoader>
            <HeroGraphic>
                <Header />

                <main>
                    <Heading className="visually-hidden">NoA Ignite</Heading>
                    <FramerRouter>
                        <Home path="/" />
                        <PrivacyPolicy path="/privacy-policy" />
                    </FramerRouter>
                </main>

                <Footer />
            </HeroGraphic>
        </FontLoader>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
