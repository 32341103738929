import React, { useCallback, useState, useLayoutEffect, useEffect, useMemo, useRef } from 'react'
import useAnimationFrame from 'use-animation-frame'
import { clamp, easeInOutQuad } from '../utils'
import { useVisibility } from '../data/hooks'

export default function Grid({
    className,
    size: incomingSize = 110,
    thickness = 10,
    defaultX = 0,
    defaultY = 0,
    gap: incomingGap = 40,
    radius: incomingRadius = 50,
    endAt = 'bottom',
}: {
    className?: string,
    endAt?: 'bottom' | 'top',
    size?: number,
    gap?: number,
    thickness?: number,
    radius?: number,
    defaultX?: number,
    defaultY?: number,
}): JSX.Element {
    const ref = useRef<HTMLCanvasElement>(null)
    const [scale] = useState(() => {
        return (1 - clamp((window.innerWidth - 400) / 600, 0, 1)) * .65
    })
    const size = incomingSize - incomingSize * scale * .65
    const gap = incomingGap - incomingGap * scale * .75
    const radius = incomingRadius - incomingRadius * scale * .5
    const visible = useVisibility(ref, 0, '0% 0% 0% 0%', false)
    const rotation = useRef(0)
    const dpr = clamp(window.devicePixelRatio * .85, 1, 2)
    const [[dimensionX, dimensionY], setDimension] = useState([0, 0])
    const xCount = Math.ceil(dimensionX / (size + gap)) + 1
    const yCount = Math.floor((dimensionY - radius) / (size + gap)) + (endAt === 'top' ? 1 : 0)
    const mouse = useRef([0, 0])
    const targetMouse = useRef([defaultX, defaultY])
    const grid = useMemo(() => {
        const grid = []

        for (let x = 0; x < xCount; x += 1) {
            for (let y = 0; y < yCount; y += 1) {
                grid.push({
                    x: x * (size + gap) - (size + gap) / 2,
                    y: y * (size + gap) - (size + gap) / 2 + (endAt === 'top' ? radius + size + gap : 0),
                    width: size,
                    height: 10,
                })
            }
        }

        return grid
    }, [xCount, yCount, gap, size, endAt, radius])
    const render = useCallback(() => {
        if (ref.current) {
            const { top } = ref.current.getBoundingClientRect()
            const context = ref.current.getContext('2d') as CanvasRenderingContext2D
            const { scrollY, innerWidth } = window 

            context.clearRect(0, 0, dimensionX * dpr, dimensionY * dpr)

            for (const element of grid) {
                const mx = mouse.current[0]
                const my = (mouse.current[1] + scrollY)
                const ex = element.x + size / 2
                const ey = element.y + size / 2 + scrollY + top
                const distance = Math.sqrt((mx - ex) ** 2 + (my - ey) ** 2)
                const height = easeInOutQuad(1 - clamp(distance / (innerWidth * .65), 0, 1)) * thickness + .5
                const offsetRange = Math.max(innerWidth * .5, 200)

                const offsetX = radius * Math.sin(rotation.current) * (clamp(distance / offsetRange, 0, 1))
                const offsetY = radius * Math.cos(rotation.current) * (clamp(distance / offsetRange, 0, 1))
                const angle = Math.atan2(my - (ey + offsetY), mx - (ex + offsetX))

                context.scale(dpr, dpr)
                context.fillStyle = '#D3E5E6'
                context.translate(element.x + size / 2 + offsetX, element.y + size / 2 + offsetY)
                context.rotate(angle)
                context.fillRect(-size / 2, -height / 2, element.width, height)
                context.setTransform(1, 0, 0, 1, 0, 0)
            }

            mouse.current[0] += (targetMouse.current[0] - mouse.current[0]) * .1
            mouse.current[1] += (targetMouse.current[1] - mouse.current[1]) * .1
        }
    }, [grid, size, radius, dimensionX, dimensionY, dpr, thickness])
    const saveSize = useCallback(() => {
        if (ref.current) {
            const { width, height } = ref.current.getBoundingClientRect()

            setDimension([width, height])
        }
    }, [])

    useEffect(() => {
        let tid: any
        const onResize = () => {
            clearTimeout(tid)
            tid = setTimeout(saveSize, 50)
        }
        window.addEventListener('resize', onResize)
    }, [saveSize])

    useLayoutEffect(() => {
        saveSize()
    }, [saveSize])

    useEffect(() => {
        const isSmallScreen = window.matchMedia('(max-width: 900px').matches

        if (!isSmallScreen) {
            const onMouseMove = (e: MouseEvent) => {
                targetMouse.current = [e.clientX, e.clientY]
            }
            window.addEventListener('mousemove', onMouseMove)

            return () => {
                window.removeEventListener('mousemove', onMouseMove)
            }
        }
    }, [render])

    useAnimationFrame(() => {
        if (visible) {
            render()
            rotation.current += .01
        }
    })

    return (
        <canvas
            className={className}
            width={dimensionX * dpr}
            height={dimensionY * dpr}
            ref={ref}
        />
    )
}