import { useEffect, useState } from 'react'
 

export function useVisibility(ref: React.RefObject<HTMLElement | SVGElement>, threshold = .2, rootMargin = '0% 0% 0% 0%', once = true): boolean {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (ref.current) {
            const observer = new IntersectionObserver(([entry]) => {
                setVisible(entry.isIntersecting)

                if (entry.isIntersecting && once) { 
                    observer.disconnect()
                }
            }, { threshold, rootMargin })

            observer.observe(ref.current) 

            return () => {
                observer.disconnect()
            }
        }
    }, [ref, threshold, once, rootMargin])

    return visible
}