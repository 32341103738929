import React, { createContext } from 'react'
import cn from 'classnames'

export function easeInOutQuad(x: number): number {
    return x < 0.5 ? 2 * x * x : 1 - ((-2 * x + 2) ** 2) / 2
}

export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max)
}

interface OnlyProps {
    if: boolean;
    children: any;
}

export function Only(props: OnlyProps): JSX.Element | null {
    return props.if ? <>{props.children}</> : null
}

// Source: https://medium.com/@Heydon/managing-heading-levels-in-design-systems-18be9a746fa3
const Level = createContext(1)

export function Section({ children }: { children: any }): JSX.Element {
    return (
        <Level.Consumer>
            {level => <Level.Provider value={level + 1}>{children}</Level.Provider>}
        </Level.Consumer>
    )
}

export function Heading({ className, variety, children }: { variety?: number, children: any, className?: any }): JSX.Element {
    return (
        <Level.Consumer>
            {level => {
                const Component = `h${Math.min(level, 6)}`

                // @ts-ignore
                return <Component className={cn('h', className, { [`h--${variety}`]: variety })}>{children}</Component>
            }}
        </Level.Consumer>
    )
}