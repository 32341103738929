import React, { useCallback, useState, useEffect } from 'react'
import FontFaceObserver from 'fontfaceobserver'

export default function FontLoader({ children }: { children: any }): JSX.Element | null {
    const [loading, setLoading] = useState(true)
    const load = useCallback(async () => {
        try {
            await Promise.all([
                new FontFaceObserver('Aften Screen', { weight: 400 }).load(),
                new FontFaceObserver('Aften Screen', { weight: 600 }).load(),
            ])
        } catch (e) {
            // do nothing
        } finally {
            // kill basic spinner
            const spinner: any = document.getElementById('spinner')

            if (spinner) {
                spinner.remove()
            }

            setLoading(false)
        }
    }, [])

    useEffect(() => {
        load()
    }, [load])

    return !loading ? <>{children}</> : null
}
