import './style/Container.scss'
import React from 'react' 
import cn from 'classnames'

export  default function Container({
    className, 
    children
}: {
    className?: string, 
    children?: any, 
}): any {  
    return (
        <div className={cn('container', className)}>
            {children}
        </div>
    )
}