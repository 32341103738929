import './style/NoaFamily.scss'
import React, { useEffect, useRef } from 'react'
import Container from './Container'
import { Heading, clamp, easeInOutQuad } from '../utils'
import Image from './Image'
import { useHeroGraphic } from '../data/graphic'

export default function NoaFamily(): JSX.Element {
    const ref = useRef<HTMLDivElement>(null)
    const img = useRef<HTMLDivElement>(null)
    const graphic = useHeroGraphic()
    const map = ['beige', 'blue', 'red']

    useEffect(() => {
        const onScroll = () => {
            if (ref.current && img.current) {
                const { top } = ref.current.getBoundingClientRect()
                const p = easeInOutQuad((clamp((-top + window.innerHeight / 2) / (window.innerHeight), -1, 1) + 1) / 2)
                const distance = Math.min(window.innerWidth * .05, 25)

                img.current.style.transform = `translate3d(
                    0, 
                    ${((p * 2 - 1) * distance).toFixed(1)}px, 
                    1px
                )`
            }
        }

        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return (
        <Container>
            <div ref={ref} className="noa-family">
                <div className="noa-family__content">
                    <Heading className="noa-family__title text text--large">Part of the <strong>NoA</strong> family</Heading>
                    <p className="noa-family__text h h--1">&mdash; NoA is an international community of creatives, built on the Nordic values of equality, diversity, and collaboration.</p>
                </div>

                <div className="noa-family__background" ref={img}>
                    <Image
                        width={920}
                        height={683}
                        src="/static/images/furry.png"
                        srcSet={`/static/images/furry_${map[graphic]}.jpg 1500w, /static/images/furry-xs_${map[graphic]}.jpg 850w`}
                        sizes="(max-width: 940px) 100vw, 920px"
                    />
                </div>
            </div>
        </Container>
    )
}