import './style/PrivacyPolicy.scss'

import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { useVisibility } from '../data/hooks'
import Container from './Container'
import { Heading } from '../utils'

export default function PrivacyPolicy(): JSX.Element {
    const ref = useRef<HTMLDivElement>(null)
    const visible = useVisibility(ref)

    const [cookieBannerToggle, setCookieToggle] = useState(false)

    useEffect(() => {
        setCookieToggle(typeof (window as any)?.showCookieBanner === 'function')
    }, [])

    const onClickHandler = () => {
        try {
      (window as any)?.showCookieBanner()
        } catch (error) {
            // Silent Error
        }
    }

    return (
        <Container>
            <div className="privacy-policy" ref={ref}>
                <Heading variety={2} className={cn('animated animated--bottom-small', { 'animated--active': visible })}>
          Processing of personal data
                </Heading>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          When you use our website and / or are in contact with us, by receiving our newsletter, applying for a job with
          us, or contacting us for potential collaborations, we will process personal information about you. Below you
          will find information about the personal data that is collected, why we do this, and your rights related to
          the processing of personal data.
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          Registered company name: <strong>NoA Ignite</strong>
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          For questions regarding the processing of your personal information, please contact:
                </p>
                <address className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          Mads Søborg Larsen
                    <br />
          Email: gdpr@noaignite.com
                    <br />
                </address>
                <Heading variety={2} className={cn('animated animated--bottom-small', { 'animated--active': visible })}>
          Why do we collect personal information, and what information do we collect?
                </Heading>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          We collect and use your personal information for different purposes depending on who you are and how we get in
          touch with you. We collect personal information for the following purposes:
                </p>
                <ol
                    className={cn('privacy-policy__list ext text--medium animated animated--bottom-small', {
                        'animated--active': visible,
                    })}
                >
                    <li>
            Distribution of marketing materials, newsletters and information about our business. This is done on the
            basis of legitimate interest or active consent.
                    </li>
                    <li>
            Answer any inquiries that come to us: Name, telephone number, e-mail address and any personal information
            that may result from the inquiry. The processing of this personal data is based on legitimate interest and
            is necessary for us to be able to assist you with your request.
                    </li>
                    <li>
            Recruitment for new positions with us. CV, cover letter, questions, certificates and references. The
            processing of personal data is based on your prior consent.
                    </li>
                    <li>
            Our website uses cookies.{' '}
                        {cookieBannerToggle && (
                            <>
                                <button type="button" onClick={() => onClickHandler()}>
                  You can read more about cookies and the type of cookies we use here
                                </button>
                .
                            </>
                        )}{' '}
            We process the personal information captured by cookies in order to better adapt the website for our users.
            The information collected via cookies is anonymous.
                    </li>
                </ol>
                <Heading variety={2} className={cn('animated animated--bottom-small', { 'animated--active': visible })}>
          Sharing of personal information
                </Heading>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          We do not pass on your personal information to others unless there is a legal basis for such disclosure.
          Examples of such a basis may be an agreement with you or a legal basis that requires us to disclose the
          information.
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          Mailchimp (for newsletters) and Teamtailor (for recruitment) use data servers to collect, store or otherwise
          process personal information on our behalf. In these cases, we have settled agreements with the parties to
          ensure information security at all stages of the process.
                </p>
                <Heading variety={2} className={cn('animated animated--bottom-small', { 'animated--active': visible })}>
          Storage of personal data
                </Heading>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          We store your personal information for as long as is necessary for the purpose for which the personal
          information was originally collected.
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          This means, for example, that the personal data we process on the basis of your consent will be deleted if you
          withdraw the consent or we no longer have a legal obligation to store the information (for example accounts or
          for legally necessary documentation). Personal information we process to fulfill an agreement with you will
          also be deleted when the agreement has been fulfilled and all obligations arising from the agreement have been
          fulfilled.
                </p>
                <Heading variety={2} className={cn('animated animated--bottom-small', { 'animated--active': visible })}>
          Your rights when we process your personal data
                </Heading>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          You have the right to access, change and / or delete all personal information we have about you. You also have
          the right to set restrictions on use, object to processing or receive an electronic copy of the personal
          information you have provided to us. You can read more about your rights on the Danish Data
          Inspectorate&apos;s website: <a href="https://www.datatilsynet.dk/">https://www.datatilsynet.dk/</a>
                </p>
                <Heading variety={2} className={cn('animated animated--bottom-small', { 'animated--active': visible })}>
          Complaints
                </Heading>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          To exercise your rights, you must contact us either by e-mail or letter. We will respond to your inquiry
          within 30 days.
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          We will ask you to confirm your identity or provide additional information before we allow you to exercise
          your rights with us. Of course, we do this to ensure that we do not give your personal information to anyone
          other than you.
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          You can withdraw your consent to the processing of your personal data at any time. The easiest way to do this
          is to send us an email.
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          If you believe that our processing of personal data does not correspond to what we have described here, or if
          we believe that we have in any way violated the Privacy Act, you can complain to the Danish Data Protection
          Authority.
                </p>
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
          You can find more information about how to contact the Danish Data Protection Authority on their website:{' '}
                    <a href="http://www.datatilsynet.dk" title="Datatilsynet.dk" rel="noopener noreferrer" target="_blank">
            datatilsynet.dk
                    </a>
          .
                </p>

                {cookieBannerToggle && (
                    <>
                        <Heading variety={2} className={cn('animated animated--bottom-small', { 'animated--active': visible })}>
              Use of cookies
                        </Heading>
                        <p
                            className={cn('text text--medium animated animated--bottom-small', {
                                'animated--active': visible,
                            })}
                        >
              Read about{' '}
                            <button type="button" onClick={() => onClickHandler()}>
                the use of cookies on our website.
                            </button>
                        </p>
                    </>
                )}
                <p className={cn('text text--medium animated animated--bottom-small', { 'animated--active': visible })}>
                    <strong>NoA Ignite</strong>
                    <br />
          A member of the NoA family
                    <br />
          Part of the{' '}
                    <a
                        href="https://www.thenorthalliance.com"
                        title="The North Alliance."
                        rel="noopener noreferrer"
                        target="_blank"
                    >
            NoA family
                    </a>
          .
                </p>
            </div>
        </Container>
    )
}
