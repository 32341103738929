import React, { createContext, useContext } from 'react'

const theme = parseInt(localStorage.getItem('_theme') as string, 10)
const context = createContext(theme)

export function HeroGraphic({ children }: { children: any }): JSX.Element {
    return <context.Provider value={theme}>{children}</context.Provider>
}

export function useHeroGraphic(): number {
    return useContext(context)
}